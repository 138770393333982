"use strict";
/*==========================================================================
0.  Gestion du menu
========================================================================== */

var toggleMenu = document.querySelector('.tpl_main_menu button');
var menu = document.querySelector('.tpl_main_menu ul');
var actualSize = window.innerWidth;
var launchResizeEnd = 0;
var menuHeight = 0; //si l'on laisse l'animation du menu pendant le changement de la taille de la fenêtre, bug visuel au moement de la transition menu desktop / mobile
//du coup, on annulle les transitions pendant les resize et on les réenclenche à la fin de l'action si c'est nécessaire
// Lorsque l'on augmente la taille de la fenêtre et passe au dessus du point de rupture, on dois mettre les attributs du menu a visible (même si les css l'afficheront de toute façon)
// Lorsque l'on augmente la taille de la fenêtre, on dois mettre les attributs du menu a visible (même si les css l'afficheront de toute façon)

window.addEventListener('resize', function () {
  showMenu(false); //on cache le menu systèmatiquement pour éviter des affichages bizare ex: menu affiché, on redimensionne au dessus du point de rupture donc le menu passe en desktop puis si l'on reviens en mobile le menu s'affiche en plein écran

  if (document.getElementById("menu").classList.contains("animation")) document.getElementById("menu").classList.remove("animation");
  if (launchResizeEnd === 0) launchResizeEnd = setTimeout(resizeEnd, 500);
});

function getMenuMinHeight() {
  //permet de récupérer la hauteur minimum du menu en mode responsive
  //document.getElementById("menu").clientHeight donne des valeurs incorrectes si le menu est affichée dans une fenêtre de hauteur trop petite
  //la hauteur du menu est donc soit la somme des hauteurs de ses enfants, soit la hauteur récupérée par document.getElementById("menu").clientHeight
  var menuHeight = 0; //on récupére la hauteur de chaque élément du menu)

  document.querySelectorAll('#menu li').forEach(function (menuElement) {
    menuHeight += menuElement.clientHeight;
  }); //cas ou le menu est plus grand que la somme de ses éléments (le menu prend toute la taille de l'écran)
  //if(document.getElementById("menu").clientHeight > menuHeight)
  //  menuHeight = document.getElementById("menu").clientHeight;

  return menuHeight;
}

function resizeEnd() {
  //si la fonction est lancée à l'issue d'un redimentionnement on réinitialise le TimeOut
  if (launchResizeEnd != 0) {
    clearTimeout(launchResizeEnd);
    launchResizeEnd = 0;
  }

  if (window.innerWidth < 970) {
    //on remets l'animation
    document.getElementById("menu").classList.add("animation");
  } else {
    //le css cache le menu mais on l'indique aux lecteurs d'écrans
    menu.setAttribute('aria-hidden', false);
  }
}

toggleMenu.addEventListener('click', function () {
  var open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
  showMenu(!open);
}); //Afficher / cacher le menu

function showMenu(afficher) {
  toggleMenu.setAttribute('aria-expanded', afficher);
  menu.setAttribute('aria-hidden', !afficher);

  if (afficher) {
    //on passe le header en position initial pour que le menu se positionne par rapport au body et non par rapport au header
    document.querySelector(".tpl_site_header").style.position = "initial"; //Gestion de la hauteur minimum du menu

    menuMinHeight = getMenuMinHeight();
    if (menuMinHeight > window.innerHeight) document.getElementById("menu").style.setProperty("min-height", menuMinHeight + 50 + "px"); //Si on affiche le menu, on décalle le haut du contenu de la taille du menu

    if (document.getElementById("menu").clientHeight > menuMinHeight) menuHeight = document.getElementById("menu").clientHeight;else menuHeight = menuMinHeight + 50;
    document.querySelector(".tpl_site_header__banner").style.marginTop = menuHeight + "px";
    menu.querySelector('a').focus();
  } else {
    //on passe le header en position initial pour que le menu se positionne par rapport au body et non par rapport au header
    document.querySelector(".tpl_site_header").style.position = "relative";
    document.querySelector(".tpl_site_header__banner").style.marginTop = "0px";
  }
} //Détection de la touche "Echap" pour sortie du menu


document.getElementById("menu").addEventListener('keyup', function (e) {
  if (e.key == "Escape") {
    showMenu(false);
  }
}); //Au chargement de la page, on regarde si on est en mode desktop ou responsive et en fonction on active l'animation du menu

document.addEventListener('DOMContentLoaded', function () {
  if (window.innerWidth < 970) {
    //on ajoutte l'animation
    document.getElementById("menu").classList.add("animation");
  } else {
    //le menu est aria-hidden true par défaut (responsive-first) on le passe a true si on est en desktop (bien que le css le montre de toute façon)
    menu.setAttribute('aria-hidden', false);
  } //on vérifie que la hauteur du menu n'est pas supérieure à la taille de la fenêtre
  //fixMenuHeight();

});
/*==========================================================================
1.  Gestion des animations
========================================================================== */
//détection IE  ou older https://codepen.io/gapcode/pen/vEJNZN

if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0) var isIe = true;else var isIe = false; //pour des questions de performance, on teste l'évenement scroll en passif.
//mais ceci n'est pas possible pour tes anciens navigateurs https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// Test via a getter in the options object to see if the passive property is accessed

var supportsPassive = false;

try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function get() {
      supportsPassive = true;
    }
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {}

var animateHTML = function animateHTML() {
  var elemsAnimate, elemsAnimateOnId, windowHeight, elemsAnimateBackground, elemsAnimateCallback, elemsAnimateDevenirClient;

  var init = function init() {
    //Par défaut on ne cache pas les éléments animés pour éviter que les blocs ne soient pas visible si le js est désactivé. On rajoute la classe animate via JS
    var elemsInitAnimatation = document.querySelectorAll("[data-animate],[data-animate-id],[data-animate-background],[data-animate-callback]");
    elemsInitAnimatation.forEach(function (target) {
      target.classList.add("animate");
    });
    elemsAnimate = document.querySelectorAll("[data-animate='true']");
    elemsAnimateOnId = document.querySelectorAll("[data-animate-id]");
    elemsAnimateBackground = document.querySelectorAll("[data-animate-background]");
    elemsAnimateCallback = document.querySelectorAll("[data-animate-callback]");
    elemsAnimateDevenirClient = document.querySelectorAll("[data-animate='devenirClient']");
    windowHeight = window.innerHeight;
    window.addEventListener("scroll", _checkPosition, supportsPassive ? {
      passive: true
    } : false);
    window.addEventListener("resize", init);

    _checkPosition(); //on lance la fonction direct au cas ou le support soit très haut et que des éléments animés soient déjà visibles

  };

  var _checkPosition = function _checkPosition() {
    //on teste les éléments ayant un data-animate="true"
    for (var i = 0; i < elemsAnimate.length; i++) {
      var posFromTop = elemsAnimate[i].getBoundingClientRect().top;

      if (posFromTop - windowHeight <= 0) {
        elemsAnimate[i].classList.add("visible");
      }
    } //on teste les éléments ayant un data-animate-id


    for (var _i = 0; _i < elemsAnimateOnId.length; _i++) {
      var id = elemsAnimateOnId[_i].getAttribute('data-animate-id');

      if (id != null && id != "") {
        var elem = document.getElementById(id);

        if (elem != null) {
          var _posFromTop = elem.getBoundingClientRect().top;

          if (_posFromTop - windowHeight <= 0) {
            elemsAnimateOnId[_i].classList.add("visible");
          }
        }
      }
    } //on teste les éléments ayant un data-animate-background="true"
    // A faire : utiliser translate pour + de performance el.style.transform = "translate3d(0px, "+(scrollPosition / displace)+"px, 0px)";


    if (!isIe) //on désctive cette partie de script pour IE car trops "d'acoups" pour certains...
      {
        for (var _i2 = 0; _i2 < elemsAnimateBackground.length; _i2++) {
          var scrolledHeight = window.pageYOffset;
          var el = elemsAnimateBackground[_i2];
          var realTop = el.offsetTop - document.getElementById("ei_tpl_head_center").offsetHeight;
          var limit = realTop + el.offsetHeight;

          if (scrolledHeight > realTop && scrolledHeight <= limit) {
            el.style.backgroundPositionY = (scrolledHeight - realTop) / 1.5 + "px"; //el.style.transform = "translate3d(0px, "+(scrolledHeight - realTop) /1.5+"px, 0px)";
          } else {
            el.style.backgroundPositionY = "0";
          }
        }
      } //on teste les éléments ayant un data-animate-callback="functionNameXXX"


    for (var _i3 = 0; _i3 < elemsAnimateCallback.length; _i3++) {
      var _posFromTop2 = elemsAnimateCallback[_i3].getBoundingClientRect().top;

      if (_posFromTop2 - windowHeight <= 0) {
        eval(elemsAnimateCallback[_i3].getAttribute('data-animate-callback'));
      }
    } //Bouton devenir client


    for (var _i4 = 0; _i4 < elemsAnimateDevenirClient.length; _i4++) {
      var _posFromTop3 = elemsAnimateDevenirClient[_i4].getBoundingClientRect().top;

      if (_posFromTop3 <= 0) {
        document.getElementById("ei_tpl_devenirclient").style.display = "table-cell";
      } else document.getElementById("ei_tpl_devenirclient").style.display = "none";
    }
  };

  return {
    init: init
  };
};

document.addEventListener('DOMContentLoaded', function () {
  animateHTML().init();
});
/*==========================================================================
2.  Afficher / cacher le formulaire de demande de contact
========================================================================== */

var timeout;

function showFormContactStep1() {
  document.getElementById("frmContact").classList.add("db"); //on mets une pause sinon l'animation css ne se joue pas (il faut que le block soit visible pour que le css ai le temps de calculer l'animation)

  timeout = window.setTimeout(showFormContactStep2, 50);
}

function showFormContactStep2() {
  window.clearTimeout(timeout);
  document.getElementById("frmContact").classList.add("visible");
  document.getElementById("frmContact").setAttribute('aria-hidden', false);
  document.getElementById("btnFrmContact").setAttribute('aria-expanded', true); //une fois affiché le formulaire, on retire le bouton se faire rappeler

  document.getElementById("btnFrmContact").style.display = "none";
}

document.addEventListener('DOMContentLoaded', function () {
  //on cache la section de détail des agences
  document.getElementById("btnFrmContact").addEventListener("click", showFormContactStep1); //si le formulaire est validé, FGEN rajoute une ancre corespondant au paramètre action du form
  //on recherche cette ancre dans l'url pour afficher le formulaire si elle est présente

  if (window.location.hash.substring(1) == "frmContact") showFormContactStep1();
});